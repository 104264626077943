import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BaseSection from './BaseSection';
import { withComponents } from '../components/Context';
import { withEdit } from '../Context';
import './vertical-timeline.scss';

class TimelineItem extends PureComponent {
  render() {
    const { isRight, item, imageFormat, showButton, components } = this.props;
    const { Image, RichText } = components;
    const {
      _id,
      title,
      color,
      iconTitle,
      image,
      link,
      otherSideImage,
      description,
      button = {},
    } = item;
    const { title: buttonTitle = "S'inscrire" } = button;
    return (
      <div className={cx('vertical--timeline__item', _id)}>
        <div className="vertical--timeline__item--icon">
          <RichText
            className="iconTitle"
            name="iconTitle"
            html={iconTitle}
            placeholder="Add a title"
            style={{ textAlign: 'center', fontSize: 14 }}
          />
        </div>
        <div className="vertical--timeline__item--content" style={{ display: 'flex' }}>
          {isRight && (
            <div className="otherside" style={{ display: 'flex', width: '55%' }}>
              <div style={{ width: '90%', float: 'left', margin: 'auto' }}>
                <Image
                  src={otherSideImage}
                  name="otherSideImage"
                  maxWidth={1000}
                  isGallery={imageFormat !== 'none'}
                  format={imageFormat}
                  link={link}
                />
              </div>
              <div style={{ width: '20%' }} />
            </div>
          )}
          <div className={cx('mainside', { right: isRight })}>
            <div className="mainside__arrow mainside__arrow--left" />
            <div className="mainside__arrow mainside__arrow--right" />
            <h2 className="mainside__title">
              <RichText
                name="title"
                html={title}
                placeholder="Add a title"
                style={{ margin: '10px 0px', fontSize: 16, fontWeight: 'bold' }}
              />
            </h2>
            <Image src={image} name="image" link={link} />
            <div style={{ padding: 15 }}>
              <RichText
                className="description background-light"
                name="description"
                html={description}
                placeholder="Add a description"
                style={{ margin: '10px 0px' }}
              />
              {showButton && (
                <div
                  className="mainside__button"
                  style={{ color, border: `2px solid ${color}`, boxShadow: `2px 2px 0 ${color}` }}
                >
                  <RichText name="button.title" html={buttonTitle} placeholder="Add a title" />
                </div>
              )}
            </div>
          </div>
          {!isRight && (
            <div className="otherside" style={{ display: 'flex', width: '55%' }}>
              <div style={{ width: '20%' }} />
              <div style={{ width: '90%', float: 'right', margin: 'auto' }}>
                <Image
                  src={otherSideImage}
                  name="otherSideImage"
                  maxWidth={1000}
                  isGallery={imageFormat !== 'none'}
                  format={imageFormat}
                  link={link}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

TimelineItem.defaultProps = {
  showButton: true,
};

TimelineItem.propTypes = {
  components: PropTypes.object.isRequired,
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    button: PropTypes.shape({ title: PropTypes.string }),
    color: PropTypes.string,
    description: PropTypes.string,
    iconTitle: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    link: PropTypes.string,
    otherSideImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.string,
  }).isRequired,
  imageFormat: PropTypes.oneOf(['none', '16/9', '4/3', 'square']).isRequired,
  isRight: PropTypes.bool.isRequired,
  showButton: PropTypes.bool,
};

const VerticalTimelineItem = withEdit(withComponents(TimelineItem));

class VerticalTimelineSection extends PureComponent {
  renderItem = (item, index) => {
    const { imageFormat, showButton } = this.props;
    const isRight = index % 2 === 1;
    return (
      <VerticalTimelineItem
        key={index}
        isRight={isRight}
        item={item}
        showButton={showButton}
        imageFormat={imageFormat}
      />
    );
  };

  render() {
    const { isEditing, items, components, ...props } = this.props;
    const { List } = components;
    return (
      <BaseSection {...props} block={this.props} isEditing={isEditing}>
        <List className="vertical--timeline" name="items" items={items} render={this.renderItem} />
      </BaseSection>
    );
  }
}

VerticalTimelineSection.defaultProps = {
  isEditing: false,
  imageFormat: '16/9',
  items: [],
  showButton: true,
};

VerticalTimelineSection.propTypes = {
  components: PropTypes.object.isRequired,
  imageFormat: PropTypes.oneOf(['none', '16/9', '4/3', 'square']),
  isEditing: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      color: PropTypes.string,
      description: PropTypes.string,
      iconTitle: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      title: PropTypes.string,
    }),
  ),
  showButton: PropTypes.bool,
};

export default withEdit(withComponents(VerticalTimelineSection));
